import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import NewsArticles from '../components/NewsArticles'
import SEO from '../components/SEO'

const News = ({ data }) => {
  const posts = data.allContentfulPost.edges
  const categories = data.allContentfulCategory.edges
  const HeroImage = data.allContentfulAsset.edges[0].node

  return (
    <Layout pageClass="page--news">
      <SEO />
      <NewsArticles
        image={HeroImage}
        categories={categories}
        posts={posts}
        allNews="true"
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulCategory {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    allContentfulPost(
      sort: { fields: [featured, publishDate], order: [DESC, DESC] }
    ) {
      edges {
        node {
          id
          slug
          title
          publishDate
          category {
            title
          }
          heroImage {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allContentfulAsset(filter: { title: { eq: "news-hero-image" } }) {
      edges {
        node {
          ...HeroImageSettings
        }
      }
    }
  }
`

export default News
