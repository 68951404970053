import { Link } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'
import Card from './Card'
import CardList from './CardList'
import Container from './Container'
import InPageNav from './InPageNav'
import LoadMoreButton from './LoadMoreButton'
import PageHeader from './PageHeader'
import Section from './Section'

const AfterParallaxWrap = styled.div`
  position: relative;
  z-index: 2;
  background-color: white;
`

const NewsArticles = ({ categories, posts, image, ...props }) => {
  const totalPosts = posts.length
  const [postsLoaded, setPostsLoaded] = useState(6)
  const postsGroup = posts.slice(0, postsLoaded)
  return (
    <>
      <PageHeader image={image} title="News" />
      <AfterParallaxWrap id="stickyNavContainer">
        <InPageNav id="stickyNav">
          <Link to="/news/" activeClassName="active-page-link">
            All News
          </Link>
          {categories.map(({ node: category }) => (
            <Link
              key={category.id}
              to={`/news/category/${category.slug}/`}
              activeClassName="active-page-link"
            >
              {category.title}
            </Link>
          ))}
        </InPageNav>
        <Container wrap="true" afterStickyNav="true">
          <Section>
            <CardList>
              {props.allNews
                ? postsGroup.map(({ node: post }, index) => {
                    return <Card key={post.id} index={index} {...post} />
                  })
                : postsGroup.map((post, index) => {
                    return <Card key={post.id} index={index} {...post} />
                  })}
            </CardList>
            {totalPosts > postsLoaded && (
              <div onClick={() => setPostsLoaded(postsLoaded + 3)}>
                <LoadMoreButton title="Load More News" />
              </div>
            )}
          </Section>
        </Container>
      </AfterParallaxWrap>
    </>
  )
}

export default NewsArticles
